import { useState } from 'react';

// styles
import styles from './messages.module.scss';

// components
import { ICON_TYPES, Icon } from '../Icon/Icon';
import { BUTTON_TYPES, Button } from '../Button/Button';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';

const MESSAGE_ICONS = {
  info: 'information',
  alert: 'alert',
  warning: 'alert',
  success: 'check',
};

interface MessageProps {
  content: string | React.ReactNode;
  type: 'success' | 'alert' | 'warning' | 'info';
  closable?: boolean;
}

export function Message({ content, type, closable = true }: Readonly<MessageProps>) {
  const [isActive, setIsActive] = useState(true);

  return (
    <>
      {isActive && (
        <div className={classNameBuilder(styles.message, styles[type])}>
          <div className={styles.content}>
            <Icon symbol={MESSAGE_ICONS[type]} type={ICON_TYPES.WEB20} />
            {content}
          </div>

          {closable && (
            <Button
              symbol="close"
              className="button--close"
              type={BUTTON_TYPES.SEAMLESS}
              onClick={() => setIsActive(false)}
            />
          )}
        </div>
      )}
    </>
  );
}
