import { useRef, useEffect, useState, RefObject } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Button as GDDSButton } from '@geberit/gdds';

// components
import { BUTTON_TYPES, BUTTON_COLORS, Button } from '../Button/Button';

// utils
import { useNord } from 'utils/hooks/use-nord';
import { useIsDesktop, useIsMobile } from 'components/App/SizeProvider';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useSuggestions } from 'utils/hooks/use-search-suggestions';

const QUERY_DELAY = 300;
const MIN_LENGTH = 3;
const MAX_LENGTH = 100;

interface SearchBarInputProps {
  getInputProps: (args: any) => Record<string, unknown>;
  handleSubmit: (args: any) => void;
  fetchData: (query: string) => Promise<string[]>;
  setItems: (items: string[]) => void;
  closeSearchOverlay?: (args: any) => void;
  value?: string;
  className?: string;
  searchPlaceholder?: string;
  hasAnimation?: boolean;
  searchInputRef?: RefObject<HTMLInputElement>;
  showSearch: boolean;
}

const SearchBarInput = ({
  getInputProps,
  handleSubmit,
  setItems,
  closeSearchOverlay,
  value: inputValue = '',
  className = '',
  searchPlaceholder = 'What are you searching for?',
  hasAnimation = false,
  showSearch,
  searchInputRef,
}: Readonly<SearchBarInputProps>) => {
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const isNord = useNord();
  const borderRef = useRef<HTMLDivElement | null>(null);
  const [animationBorder, setAnimationBorder] = useState('');
  const translate = useTranslationFunction();
  const suggestions = useSuggestions(inputValue);

  const processInputCallback = useDebouncedCallback(async (value) => {
    if (value && value.length >= MIN_LENGTH && value.length <= MAX_LENGTH) {
      const reducedSuggestions = isMobile ? suggestions.slice(0, 5) : suggestions.slice(0, 8);
      setItems(reducedSuggestions);
    } else {
      setItems([]);
    }
  }, QUERY_DELAY);

  useEffect(() => {
    // add animation class for border translate animation
    if (showSearch && !animationBorder) setAnimationBorder('c-search-bar__line--animation');
  }, [animationBorder, showSearch]);

  const activeColor = inputValue && inputValue.length >= 1 ? 'c-search-bar--active' : '';
  const isFullScreenlayer = isDesktop && hasAnimation;
  const mobileCellWidth = hasAnimation ? '11' : '12';

  return (
    <div className={classNameBuilder('c-search-bar', className)}>
      <div className="grid-container grid-x">
        {isFullScreenlayer && (
          <>
            {isNord ? (
              <GDDSButton
                stylingType="icon"
                onClick={closeSearchOverlay}
                isIcon
                height={{ xsmall: 3 }}
                symbol="Close"
                className="c-search__close"
              />
            ) : (
              <Button
                type={BUTTON_TYPES.PLAIN}
                symbol="close"
                onClick={closeSearchOverlay}
                className="c-search__close"
                aria-label={translate('web20_search_close_label')}
              />
            )}
          </>
        )}
        <div
          className={classNameBuilder(
            `cell small-${mobileCellWidth} c-search-bar__line ${animationBorder} ${activeColor}`,
            isFullScreenlayer && 'medium-8',
          )}
          ref={borderRef}
        >
          <input
            ref={searchInputRef}
            className="c-search-bar__input small-11 medium-11 large-11"
            name="q"
            maxLength={MAX_LENGTH}
            type="text"
            value={inputValue}
            placeholder={!inputValue ? searchPlaceholder : undefined}
            autoComplete="off"
            {...getInputProps({
              onChange: (e) => processInputCallback(e.target.value),
            })}
            // handlesubmit after pressing enter
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                handleSubmit(event);
              }
            }}
          />
          <div className="small-1 medium-1 large-1 c-search-bar__btn-container">
            <Button
              symbol="search"
              className="c-search-bar__btn"
              onClick={() => handleSubmit(inputValue)}
              type={BUTTON_TYPES.PLAIN}
              color={BUTTON_COLORS.PRIMARY}
              aria-label={translate('web20_search_submit_label')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBarInput;
