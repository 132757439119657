import { headerTrackingActionTemplate } from '../../features/header/trackingActions';

export function languageSwitchTrackingAction(eventLabel) {
  return headerTrackingActionTemplate('Language-Switch', eventLabel);
}

export function navigationTrackingAction(eventLabel) {
  return headerTrackingActionTemplate('Navigation', eventLabel);
}

export function metaNavigationTrackingAction(eventLabel) {
  return headerTrackingActionTemplate('Meta-Navigation', eventLabel);
}

export function searchTrackingAction(eventLabel) {
  return headerTrackingActionTemplate('Search', eventLabel);
}

export function breadcrumbTrackingAction(eventLabel) {
  return headerTrackingActionTemplate('Breadcrumb', eventLabel);
}

export function siteShortcutTrackingAction(eventLabel) {
  return headerTrackingActionTemplate('Shortcut-Action', eventLabel);
}
