import { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import axios from 'axios';

// utils
import getEndpoint from 'utils/endpoints';
import { globalsSearchSegmentSelector } from 'utils/selectors/globalsSelectors';

export function useSuggestions(value) {
  const [suggestions, setSuggestions] = useState([]);
  const state = useStore();
  const segment = useSelector(globalsSearchSegmentSelector);
  const endpoint = `${getEndpoint('suggest', state)}`;

  useEffect(() => {
    if (!value) {
      setSuggestions([]);
    }

    axios
      .get(endpoint, {
        params: {
          segment,
          q: value,
        },
      })
      .then((response) => {
        setSuggestions(response.data.suggestions);
      })
      .catch((e) => {
        setSuggestions([]);
      });
  }, [endpoint, segment, value]);

  return suggestions;
}
