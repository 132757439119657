import { useSelector } from 'react-redux';

// utils
import { locatorConfigurationSelector } from 'utils/selectors/globalsSelectors';

export function useLocatorConfiguration() {
  const locatorConfig = useSelector(locatorConfigurationSelector);

  return {
    ...locatorConfig,
    dealerPage: {
      ...locatorConfig?.dealerPage,
      detailPageHash: 'further-equipment',
    },
    consent: {
      ...locatorConfig?.consent,
      type: 'comfort' as const,
    },
  };
}
