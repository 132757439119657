import { trackTemplate } from '../../utils/tracking';

/**
 * Button click
 * @param eventAction
 * @param eventLabel
 */
export function headerTrackingActionTemplate(eventAction, eventLabel) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Header',
      action: eventAction,
      label: eventLabel,
      value: '0',
    },
  });
}

export function siteShortcutTrackingAction(eventLabel) {
  return headerTrackingActionTemplate('Site-Shortcuts', eventLabel);
}
